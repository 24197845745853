import { getFallbackLocale } from '@/i18n';

// Returns the best matching supported locale from the list
// of locale preferences. This prefers an exact locale match,
// but falls back to generic / variants where supported or
// necessary.
export function getLocaleToUse(supported, preferences) {
  if (supported.length === 1) {
    return supported[0];
  }

  const generic = (s) => s.split(/[-_]/)[0];

  for (const pref of preferences) {
    // Filter supported locales by everything that matches
    // the generic locale (i.e. `en`, `en-GB` and `en-US` for `en-US`).
    const base = generic(pref);
    const potential = supported.filter((s) => generic(s) === base);

    if (!potential.length) {
      continue;
    }

    // Return exact match
    if (potential.includes(pref)) {
      return pref;
    }

    // Return generic version
    if (potential.includes(base)) {
      return base;
    }

    // Return first variant
    return potential[0];
  }

  // No matches, return the first supported language
  return supported.length ? supported[0] : null;
}

export const localeMap = {
  ar: 'Arabic',
  en: 'English',
  es: 'Español',
  nl: 'Nederlands',
  pl: 'Polski',
  tr: 'Türkçe',
  de: 'Deutsch',
  it: 'Italiano',
  fr: 'Français',
};

/**
 * Returns a translation with the best matching locale from the given
 * locale map. If fallback = false, only the literal locale passed in
 * is considered as a valid option. If fallback = true, falling back
 * to a backup plocale is attempted using getLocaleToUse.
 */
export function translateObj(locale, map, fallback = true) {
  const translation = map[locale];
  if (translation) {
    return translation;
  }

  if (!fallback) {
    return '';
  }

  // Try to find a matching locale with an available option
  // Use the passed locale + global fallback locale as preferences
  const preferences = [locale, getFallbackLocale()];

  // Supported are all locales that have a non-empty entry
  const supported = Object.keys(map).filter((l) => !!map[l]);

  const fallbackLocale = getLocaleToUse(supported, preferences);
  return fallbackLocale ? map[fallbackLocale] : '';
}

export default {
  localeMap,

  /**
   * Will lookup translation in the item given, the item is a 'complex' object containing multiple translations.
   *
   * Important note: if fallback is set to false, this will not do any "smart" fallback either. You should make
   * sure that the locale passed into this method is a locale that is expected by the language object
   * you pass in. Screening.vue::setScreening() forces the active locale to one in the screening's
   * locale list to achieve this.
   */
  translate(locale, item, key, fallback = true) {
    return translateObj(locale, item[key], fallback);
  },
};
